import styled from 'styled-components';

const Panel = styled.section`
  padding: 16px;
  @media (min-width: ${(props => props.theme.breakpoints.medium)}) {
      padding: 24px;
  }
  @media (min-width: ${(props => props.theme.breakpoints.xx_large)}) {
      padding: 32px;
  }
  background: ${props => props.theme.colors.LM_N0};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  > * {
    margin-block-end: 0;
    margin-block-start: 1em;
  }
  & *:first-child {
    margin-block-start: 0;
  }
`;

export default Panel;
