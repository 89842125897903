import { Temporal } from '@js-temporal/polyfill';
import { atom } from 'recoil';
import { IPricing } from 'types/pricing';

const pricingAtom = atom<IPricing[]>({
    key: 'pricingAtom',
    default: []
});

export const getCurrencySymbol = (currencyToMap: string) => {
    const mappedCurrency: any = {
        'GBP': '£',
        'EUR': '€',
        'USD': '$'
    }
    return mappedCurrency[currencyToMap];
};

export const calculatePricePerNight = (checkIn: string, checkOut: string, totalPrice: number) => {
    if (!checkIn || !checkOut){
        return [0,0];
    }
    const numberOfNights = Temporal.PlainDate.from(checkIn).until(checkOut).days;
    const pricePerNight = Math.ceil(totalPrice / numberOfNights);
   
    return [pricePerNight, numberOfNights];
}

export default pricingAtom;
