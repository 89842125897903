import React, { ReactNode } from 'react';
import styled from 'styled-components';

// Assume span 12 cols on mobile
const Column = styled.div`
    grid-column-end: span ${(props => props.columnSpanDefault)};
    @media (min-width: ${(props => props.theme.breakpoints.medium)}) {
        grid-column-end: span ${(props: IPageProps) => props.columnSpanLarge};
    }
    @media (min-width: ${(props => props.theme.breakpoints.large)}) {
        grid-column-end: span ${(props: IPageProps) => props.columnSpanLarge};
    }
    @media (min-width: ${(props => props.theme.breakpoints.x_large)}) {
        grid-column-end: span ${(props: IPageProps) => props.columnSpanXLarge};
    }
    @media (min-width: ${(props => props.theme.breakpoints.xx_large)}) {
        grid-column-end: span ${(props: IPageProps) => props.columnSpanXXLarge};
    }
    margin: 0;
`;

interface IPageProps {
    children: ReactNode | string
    columnSpanMedium?: number
    columnSpanLarge?: number
    columnSpanXLarge?: number
    columnSpanXXLarge?: number
    columnSpanDefault?: number,
    className?: string
}

const Index = ({ children, columnSpanMedium, columnSpanLarge, columnSpanXLarge, columnSpanXXLarge, columnSpanDefault = 12, className }: IPageProps) => {
    const columnProps = {
        columnSpanMedium,
        columnSpanLarge,
        columnSpanXLarge,
        columnSpanXXLarge,
        columnSpanDefault
    }
    return <Column {...columnProps} className={className}>{children}</Column>;
};
export default Index;
