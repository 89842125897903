import Spacer from 'components/Spacer';
import styled from 'styled-components';
import typographyGenerator from 'typography/generator';

const Container = styled.header<{ marginBottom: number }>`
  margin: 0px 6px;
  padding: 24px 0px;
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => `${props.marginBottom}px`};
  @media (max-width: 440px) {
    flex-direction: column;
  }
`;

const LeftSideDiv = styled.div`
    ${(props => typographyGenerator('label', props.theme))}
    display: flex;
    color: ${(props => props.theme?.colors.LM_N100)};
    a {
        color: ${(props => props.theme?.colors.BB100)};
    }
    @media (max-width: 440px) {
        justify-content: center;
        align-items: center;
    }
`;

const RightSideDiv = styled.div`
    ${(props => typographyGenerator('label', props.theme))}
    display: flex;
    a {
        color: ${(props => props.theme?.colors.BB100)};
    }
    @media (max-width: 440px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        a {
            margin-top: 16px;
        }
    }
`;

interface IPageFooter {
  marginBottom?: number
}

const PageFooter: React.FC<IPageFooter> = ({ marginBottom = 0 }) => (
  <Container marginBottom={marginBottom}>
    <LeftSideDiv>{'Powered by'}{'\u00a0'}<a target="_blank" rel="noreferrer" href="https://travelnest.com/">{'Travelnest'}</a></LeftSideDiv>
    <RightSideDiv>
      {<a target="_blank" rel="noreferrer" href="https://travelnest.freshdesk.com/a/solutions/articles/101000473556">Terms and conditions</a>}
      <Spacer horizontal={16} vertical={0} />
      {<a target="_blank" rel="noreferrer" href="https://travelnest.com/privacy-policy">Privacy policy</a>}
    </RightSideDiv>
  </Container>
);

export default PageFooter;
