import { textStyles } from './types';

const typographyGenerator = (type: textStyles, theme: any) => {
  const themeForType = theme.fonts[type];
  return `
    font-size: ${themeForType.fontSizeMobile};
    line-height: ${themeForType.lineHeightMobile};
    font-weight: ${themeForType.fontWeight};
    font-family: ${themeForType.fontFamily};
    color: ${theme.colors[ themeForType.color ]};
    @media (min-width: ${theme.breakpoints.medium}) {
      font-size: ${themeForType.fontSizeDesktop};
      line-height: ${themeForType.lineHeightDesktop};
    }
`
}

export default typographyGenerator;