import { Temporal } from '@js-temporal/polyfill';
import { IDirectDiscount } from 'types/content';
import { ILineItem } from 'types/pricing';

export interface IStayAtom {
    currency?: string;
    startDate?: string;
    endDate?: string;
    adults: number;
    children: number;
    infants: number;
    lineItems?: ILineItem[];
    baseStayPrice?: number;
    totalBeforeDiscount?: number;
    totalToPayToday?: number;
    isDateSelected?: boolean;
    hasAvailability?: boolean;
    minStay?: number;
    appliedDiscount?: IDirectDiscount | null;
    error?: string;
}

export const hasSelectedStay = (stay: IStayAtom) => stay.isDateSelected && stay.hasAvailability;

export const formatStayDates = (checkIn?: string, checkOut?: string) => {
    if (!checkIn || !checkOut) return 'No dates selected';
    const monthNames = ['Jan', 'Feb', 'Mar', 'April', 'May', 'June',
        'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const startDate = Temporal.PlainDate.from(checkIn);
    const endDate = Temporal.PlainDate.from(checkOut);
    const startDateMonth = monthNames[startDate.month - 1];
    const endDateMonth = monthNames[endDate.month - 1];
    const startDateYear = startDate.year;
    const endDateYear = endDate.year;
    return `${startDate.day} ${startDateMonth} ${startDateYear != endDateYear ? startDateYear : ''} - ${endDate.day} ${endDateMonth} ${endDateYear}`;
}

export const validateStayDates = (checkIn: any, checkOut: any) => {
    const iso8601DateCheck = /^\d{4}-\d{2}-\d{2}$/;
    if (!checkIn || !checkOut || typeof checkIn !== 'string' || typeof checkOut !== 'string' || !iso8601DateCheck.test(checkIn) || !iso8601DateCheck.test(checkIn)) {
        return false;
    }
    return true;
}
