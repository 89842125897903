import styled from 'styled-components';
import typographyGenerator from 'typography/generator';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const DetailsDiv = styled.div`
  ${(props) => typographyGenerator('body', props.theme)}
  display: row;
  flex-direction: column;
  width: 100%;
`;

const ActionDiv = styled.div`
  ${(props) => typographyGenerator('body', props.theme)}
  display: flex;
  align-items: center;
  color: ${(props) => props.theme?.colors.BB100};
  :hover {
    cursor: pointer
  }
`;

const TitleLabel = styled.div`
  ${(props) => typographyGenerator('bold_label', props.theme)}
`;

const ItemsDiv = styled.div``;

interface ITableCell {
    title: string;
    items: string[];
    actionTitle?: string;
    handleAction?: any;
}

const TableCell: React.FC<ITableCell> = ({
    title,
    items,
    actionTitle,
    handleAction,
}) => {
    return (
        <Container>
            <DetailsDiv>
                <TitleLabel>{title}</TitleLabel>
                {items.map((item, ndx) => (
                    <ItemsDiv key={ndx}>{item}</ItemsDiv>
                ))}
            </DetailsDiv>
            {
              actionTitle && handleAction ? <ActionDiv onClick={handleAction}>{actionTitle}</ActionDiv> : <></>
            }
        </Container>
    );
};

export default TableCell;
