import React from 'react';
import styled from 'styled-components';
import typographyGenerator from './generator';
import { IBaseTypography } from './types';

const H1 = styled.h1<{ bold: boolean }>`
  ${(props => typographyGenerator('h1', props.theme))}
  ${(props => props.bold && `font-weight: 700`)}
  @media (min-width: ${(props => props.theme.breakpoints.medium)}) {
    font-size: 32px;
  }
  @media (min-width: ${(props => props.theme.breakpoints.large)}) {
      font-size: 40px;
  }
  @media (min-width: ${(props => props.theme.breakpoints.xx_large)}) {
      font-size: 48px;
}
`;

const H2 = styled.h2<{ bold: boolean }>`
  ${(props => typographyGenerator('h2', props.theme))}
  ${(props => props.bold && `font-weight: 700`)}
`;

const H3 = styled.h3<{ bold: boolean }>`
  ${(props => typographyGenerator('h3', props.theme))}
  ${(props => props.bold && `font-weight: 700`)}
`;

const H4 = styled.h4<{ bold: boolean }>`
  ${(props => typographyGenerator('h4', props.theme))}
  ${(props => props.bold && `font-weight: 700`)}
`;

const H5 = styled.h5<{ bold: boolean }>`
  ${(props => typographyGenerator('h5', props.theme))}
  ${(props => props.bold && `font-weight: 700`)}
`;

export interface IHeadingProps extends IBaseTypography {
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
}

const renderText = ({ children, type, className, bold = false }: IHeadingProps) => {
  switch (type) {
    case 'h1':
      return <H1 className={className} bold={bold}>{children}</H1>
    case 'h2':
      return <H2 className={className} bold={bold}>{children}</H2>
    case 'h3':
      return <H3 className={className} bold={bold}>{children}</H3>
    case 'h4':
      return <H4 className={className} bold={bold}>{children}</H4>
    case 'h5':
      return <H5 className={className} bold={bold}>{children}</H5>
  }
}

const Wrapper: React.FC<IHeadingProps> = ({ children, type, className, bold }) => {
  return renderText({ children, type, className, bold });
};

export default Wrapper;