import Spacer from 'components/Spacer';
import { getCurrencySymbol } from 'state/atoms/pricing';
import styled from 'styled-components';
import typographyGenerator from 'typography/generator';

interface IPricingCellProps {
  title: string;
  lineItems: { description: string; amount: number }[];
  currency: string;
  itemVerticalPadding?: number;
  totalAfterReduction: number;
  totalBeforeReduction: number;
  discountInvalid: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DetailsDiv = styled.div`
  ${(props) => typographyGenerator('body', props.theme)}
  display: row;
  flex-direction: column;
  width: 100%;
`;

const TotalDiv = styled.div`
  ${(props) => typographyGenerator('body', props.theme)}
  font-weight: 700;
  display: flex;
  align-items: center;
  color: ${(props => props.theme?.colors.LM_N100)};
`;

const TitleLabel = styled.div`
  ${(props) => typographyGenerator('bold_label', props.theme)}
`;

const ItemContainer = styled.div`
`;

const ItemsDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PricingCell: React.FC<IPricingCellProps> = ({
  title,
  lineItems,
  currency,
  itemVerticalPadding = 0,
  totalAfterReduction,
  totalBeforeReduction,
  discountInvalid,
}) => {
  const symbol = getCurrencySymbol(currency);

  return (
    <Container>
      <DetailsDiv>
        <TitleLabel>{title}</TitleLabel>
        {lineItems.map((item, ndx) => (
          <ItemContainer key={ndx}>
            <Spacer vertical={itemVerticalPadding} horizontal={0} />
            <ItemsDiv>
              {item.description}
              <Spacer vertical={0} horizontal={8} />
              {item.description.includes('Discount') && discountInvalid
                ? 'n/a'
                : `${item.amount < 0 ? '- ' : ''} ${symbol}${Math.abs(item.amount).toFixed(2)}`
              }
            </ItemsDiv>
          </ItemContainer>
        ))}
      </DetailsDiv>
      <Spacer vertical={itemVerticalPadding} horizontal={0} />
      <TotalContainer>
        <TotalDiv>Total ({currency})</TotalDiv>
        {discountInvalid && <TotalDiv>{symbol}{totalBeforeReduction.toFixed(2)}</TotalDiv>}
        {
          !discountInvalid &&
          (totalAfterReduction === totalBeforeReduction ?
            <TotalDiv>{`${symbol}${totalAfterReduction.toFixed(2)}`}</TotalDiv> :
            <TotalDiv>
              <s>{symbol}{totalBeforeReduction.toFixed(2)}</s>
              <Spacer vertical={0} horizontal={10} />
              {symbol}{totalAfterReduction.toFixed(2)}
            </TotalDiv>)
        }
      </TotalContainer>
    </Container>
  );
};

export default PricingCell;
