import styled from 'styled-components';
import typographyGenerator from 'typography/generator';

const HostContainer = styled.div`
  ${(props) => typographyGenerator('label', props.theme)}
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  padding: 0px 0px 0px 0px;
  a{ 
      cursor: pointer;
      color: ${(props => props.theme?.colors.BB100)};
  }
`;

const HostImage = styled.img`
  width: 16px;
  height: 16px;
  margin: 0px 6px 0px 0px;
`;

interface IAvatar {
    image: string
    name: string,
    link?: string
}

const Avatar: React.FC<IAvatar> = ({ name, image, link }) => {
    return (
        <HostContainer>
            <HostImage aria-label={`Host ${name}`} src={image} /><p>{'Hosted by'}&nbsp;</p>
            <a href={link}>{name}</a>
        </HostContainer>
    );
}

export default Avatar;
