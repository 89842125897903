import styled from 'styled-components';
import HelpCircleIcon from 'public/icons/help-circle.svg';
import typographyGenerator from 'typography/generator';

const HelpContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px;
  height: 32px;
  min-width:32px;
  background: #f5f6f9;
  border-radius: 16px;
  a {
    justify-content: center;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;

const LinkContainer = styled.div`
    ${(props) => typographyGenerator('label', props.theme)}
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    p{
        padding-left: 4px;
        @media (max-width: ${(props) => props.theme.breakpoints.small}) {
            display: none;
        }
    }
`

const HelpChip: React.FC<{ propertyId?: string }> = ({ propertyId }) => {

  return (
    <HelpContainer>
      <a
        target="_blank"
        rel="noreferrer"
        href={`/support?property_id=${propertyId}`}
      >
        <LinkContainer><HelpCircleIcon aria-label="Help" /><p>Help</p></LinkContainer>
      </a>
    </HelpContainer>
  );
}

export default HelpChip;
