import React from 'react';
import styled from 'styled-components';
import typographyGenerator from './generator';
import { IBaseTypography } from './types';

const Lead = styled.p`
  ${(props => typographyGenerator('lead', props.theme))}
`;

const BodyLongform = styled.p`
  ${(props => typographyGenerator('body_longform', props.theme))}
`;

const Body = styled.p`
  ${(props => typographyGenerator('body', props.theme))}
`;

const Label = styled.p`
  ${(props => typographyGenerator('label', props.theme))}
`;

const Footnote = styled.p`
  ${(props => typographyGenerator('footnote', props.theme))}
`;

const Caption = styled.h5`
  ${(props => typographyGenerator('caption', props.theme))}
`;

export interface ITextProps extends IBaseTypography {
  type: 'lead' | 'body' | 'body_longform' | 'label' | 'footnote' | 'caption';
}

const renderText = ({ children, type, className}: ITextProps) => {
  switch(type) {
    case 'lead':
      return <Lead className={className}>{children}</Lead>
    case 'body_longform':
      return <BodyLongform className={className}>{children}</BodyLongform>
    case 'body':
      return <Body className={className}>{children}</Body>  
    case 'label':
      return <Label className={className}>{children}</Label>
    case 'footnote':
      return <Footnote className={className}>{children}</Footnote>
    case 'caption':
      return <Caption className={className}>{children}</Caption>
    }
}

// className allows styled() wrapper to be used
const Wrapper: React.FC<ITextProps> = ({ children, type, className }) => {
  return renderText({ children, type, className });
};

export default Wrapper;