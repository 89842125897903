import styled from 'styled-components';
import typographyGenerator from 'typography/generator';

const ChipContainer = styled.div`
  ${(props) => typographyGenerator('label', props.theme)}
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px 0px 4px;
  min-width: 32px;
  height: 32px;
  background: #f5f6f9;
  border-radius: 16px;
  cursor: pointer;
`;

const ChipIcon = styled.img``;

interface IPageChip {
    title: string,
    icon: string,
    action: () => void,
}

const Chip: React.FC<IPageChip> = ({ title, icon, action }) => {

    return (
        <ChipContainer onClick={action}>
            <ChipIcon aria-label={title} src={icon} />
            {title}
        </ChipContainer>
    );
}

export default Chip;
