import styled from 'styled-components';

const PageHeaderBar = styled.header`
  max-height: 64px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
interface IPageHeader {
  leftItem?: any;
  rightItem?: any;
}

const PageHeader: React.FC<IPageHeader> = ({ leftItem, rightItem }) => {

  return (
    <PageHeaderBar>
      {leftItem ? leftItem : null}
      {rightItem ? rightItem : null}
    </PageHeaderBar>
  );
}

export default PageHeader;
