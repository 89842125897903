import styled from 'styled-components';
import typographyGenerator from 'typography/generator';
import { MouseEventHandler } from 'react';

const Button = styled.button`
    ${(props => typographyGenerator('body', props.theme))};
    border-width: 0px;
    padding: 10px 16px;
    min-width: 200px;
    width: 100%;
    height: 64px;
    background: ${(props => props.theme?.colors.PI100)};
    border-radius: 8px;
    color: ${(props => props.theme?.colors.LM_N0)};
    text-align: center;
    @media screen and (max-width: 375px) {
        min-width: 140px;
        padding: 0px 16px !important;
    }
    @media screen and (min-width: 667px) {
        max-width: 279px;
    }
    transition: background-color 300ms ease-out;
    :focus{
        border: 2px solid ${(props => props.theme?.colors.BB100)};
    }
    :hover {
        transition: background-color 300ms ease-out;
        background: ${(props => props.theme?.colors.PI110)};
        cursor: pointer;
    }
    :focus:hover {
        background: ${(props => props.theme?.colors.PI110)} !important;
        transition: background-color 300ms ease-out;
    }
    `


interface IStyledButton {
    title: string,
    type?: 'button' | 'submit' | 'reset' | undefined,
    handleClick?: MouseEventHandler | undefined,
    disabled?: boolean,
    height?: number,
    fontSize?: number,
    className?: string,
}

const StyledButton: React.FC<IStyledButton> = ({className, title, handleClick, type = 'button', disabled = false}) => {
    return (
        <Button className={className} type={type} onClick={handleClick} disabled={disabled}>{title}</Button>
    );
}

export default StyledButton
